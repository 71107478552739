@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&display=swap";
.mp-calculator-plugin .t7t-accordion {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 30px 42px;
  display: flex;
}

.mp-calculator-plugin .t7t-accordion:last-of-type {
  border-bottom: none !important;
}

.mp-calculator-plugin .t7t-accordion p {
  text-align: left !important;
  color: #fff !important;
  font-size: 21px !important;
  font-weight: 400 !important;
}

.mp-calculator-plugin .t7t-panel {
  background-color: #ffe6ea;
}

.mp-calculator-plugin .t7t-panel p {
  text-align: left !important;
  color: #fff !important;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.mp-calculator-plugin .t7t-panel .content {
  opacity: 0;
  transition-property: max-height;
  transition-duration: .2s;
  transition-timing-function: ease-out;
  transform: translateY(-1rem);
}

.mp-calculator-plugin .t7t-panel.active .content {
  opacity: 1;
  padding: 31px 65px;
  transition-property: opacity, transform;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  transform: translateY(0);
}

.mp-calculator-plugin .t7t-panel:not(.active) {
  opacity: 0;
  max-height: 0;
  position: relative;
  overflow: hidden;
}

@media screen and (width <= 767px) {
  .mp-calculator-plugin .t7t-accordion {
    padding: 30px 25px;
  }

  .mp-calculator-plugin .t7t-panel.active .content {
    padding: 31px 25px;
  }
}

.mp-calculator-plugin .form-btn {
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 131px;
  height: 48px;
  padding: 13px 20px;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
}

.mp-calculator-plugin .form-label {
  color: #0d2a25 !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 23px !important;
}

.mp-calculator-plugin .calculator-title {
  letter-spacing: -.01em !important;
  text-align: left !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 36px !important;
  font-weight: 600 !important;
  line-height: 43.88px !important;
}

.mp-calculator-plugin .calculator-description {
  color: #0d2a25 !important;
  text-align: left !important;
  max-width: 600px !important;
  margin-top: 33px !important;
  margin-right: auto !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 21.79px !important;
}

.mp-calculator-plugin .calculator-disclaimer {
  color: #0d2a25;
  max-width: 400px;
  margin-top: 30px;
  margin-right: auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left !important;
}

.mp-calculator-plugin .calculator-results-container {
  box-shadow: 0 4px 30px #00000040;
}

.mp-calculator-plugin .calculator-results-container .calculator-results {
  box-sizing: border-box;
  border-radius: 15px 15px 0 0;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  padding: 60px 32px;
  display: flex;
}

.mp-calculator-plugin .calculator-results-container .calculator-results_heading {
  text-align: center;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.26px;
}

.mp-calculator-plugin .calculator-results-container .calculator-results_total {
  text-align: center;
  color: #fff;
  font-family: Open Sans, sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 87.16px;
}

.mp-calculator-plugin .calculator-results-container .calculator-results_text {
  text-align: center;
  color: #fff;
  margin-top: 30px;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 29.26px;
}

.mp-calculator-plugin .calculator-results-container .calculator-results_text .value {
  font-weight: 700;
}

.mp-calculator-plugin .calculator-results-next-steps {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0;
  min-height: 296px;
  margin-bottom: 20px;
  padding: 37px 21px;
}

.mp-calculator-plugin .calculator-results-next-steps_heading {
  color: #000;
  font-family: Open Sans, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 43.58px;
}

.mp-calculator-plugin .calculator-results-next-steps_text {
  color: #0d2a25;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
}

.mp-calculator-plugin .calculator-results-next-steps_button {
  width: fit-content;
  margin: 35px auto auto;
}

.mp-calculator-plugin .calculator-results-next-steps_button .btn {
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 131px;
  height: 48px;
  padding: 13px 20px;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
}

.mp-calculator-plugin .calculator-results-next-steps_button .btn:nth-child(2) {
  margin-left: 20px;
}

@media screen and (width <= 767px) {
  .mp-calculator-plugin .calculator-results-next-steps_button {
    flex-direction: column;
    display: flex;
  }

  .mp-calculator-plugin .calculator-results-next-steps_button .btn {
    width: 100%;
    margin-bottom: 10px;
    display: block;
    margin-left: 0 !important;
  }
}

.mp-calculator-plugin .calculator-tabs {
  margin-top: 40px;
  margin-left: 26px;
  display: flex;
}

.mp-calculator-plugin .calculator-tabs .calculator-tab {
  cursor: pointer !important;
  color: #000000de !important;
  border-radius: 5px 5px 0 0 !important;
  width: fit-content !important;
  padding: 14px 19px !important;
  font-family: Open Sans, sans-serif !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 27.19px !important;
}

.mp-calculator-plugin .calculator-tabs .calculator-tab.active {
  background-color: #f6f7f2;
}

.mp-calculator-plugin .calculator-tabs .calculator-tab:not(.active) {
  background-color: #fff;
  border: 2px solid #f6f7f2;
}

@media screen and (width <= 767px) {
  .mp-calculator-plugin .calculator-tabs {
    margin-left: 0;
  }
}

.your-question-answered {
  color: #0d2a25 !important;
  letter-spacing: -.01em !important;
  text-align: left !important;
  margin-left: 20px !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 36px !important;
  font-weight: 600 !important;
  line-height: 43.88px !important;
}
/*# sourceMappingURL=library.css.map */
