.mp-calculator-plugin {
    .calculator-title {
        font-size: 36px !important;
        font-weight: 600 !important;
        line-height: 43.88px !important;
        font-family: 'Montserrat', sans-serif !important;
        letter-spacing: -0.01em !important;
        text-align: left !important;
    }
    
    .calculator-description {
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 21.79px !important;
        color: #0D2A25 !important;
        margin-top: 33px !important;
        max-width: 600px !important;
        margin-right: auto !important;
        text-align: left !important;
    }

    .calculator-disclaimer {
        font-size: 16px;
        margin-top: 30px;
        font-weight: 400;
        line-height: 21.79px;
        color: #0D2A25;
        max-width: 400px;
        margin-right: auto;
        text-align: left !important;
    }

.calculator-results-container {
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);

        .calculator-results {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 60px 32px;
            border-radius: 15px 15px 0px 0px;
            height: fit-content;
            box-sizing: border-box;

            &_heading {
                font-weight: 700;
                text-align: center;
                font-size: 24px;
                font-family: 'Montserrat', sans-serif;
                line-height: 29.26px;
                color: #FFF;
            }

            &_total {
                text-align: center;
                font-weight: 700;
                font-size: 64px;
                line-height: 87.16px;
                color: #FFF;
                font-family: 'Open Sans', sans-serif;
            }

            &_text {
                margin-top: 30px;
                text-align: center;
                font-weight: 400;
                font-size: 16px;
                line-height: 29.26px;
                font-family: 'Open Sans', sans-serif;
                color: #fff;

                .value {
                    font-weight: 700;
                }
            }
        }
    }
}