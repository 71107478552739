.mp-calculator-plugin {
    .form-btn {
        padding: 13px 20px;
        border-radius: 4px;
        min-width: 131px;
        box-sizing: border-box;
        height: 48px;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 21.79px;
    }

    .form-label {
        font-size: 17px !important;
        font-weight: 400 !important;
        line-height: 23px !important;
        color: #0D2A25 !important;
    }
}