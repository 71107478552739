.mp-calculator-plugin {
.calculator-tabs {
        margin-left: 26px;
        display: flex;
        margin-top: 40px;

        .calculator-tab {
            font-family: 'Open Sans', sans-serif !important;
            cursor: pointer !important;
            border-radius: 5px 5px 0px 0px !important;
            padding: 14px 19px !important;
            width: fit-content !important;
            font-size: 20px !important;
            font-weight: 700 !important;
            line-height: 27.19px !important;
            color: rgba(0, 0, 0, 0.87) !important;

            &.active {
                background-color: #f6F7f2;
            }

            &:not(.active) {
                background-color: #FFF;
                border: 2px solid #f6F7f2;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .calculator-tabs {
            margin-left: 0px;
        }
    }
}