.mp-calculator-plugin {
    .t7t-accordion {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 42px;
        cursor: pointer;

        &:last-of-type {
            border-bottom: none !important;
        }

        p {
            text-align: left !important;
            color: #FFFFFF !important;
            font-size: 21px !important;
            font-weight: 400 !important;
        }
    }

    .t7t-panel {
        background-color: #FFE6EA;

        p {
            font-family: Roboto, Helvetica, Arial, sans-serif !important;
            text-align: left !important;
            color: #FFFFFF !important;
            font-size: 16px !important;
            font-weight: 400 !important;
        }
        
        .content {
            opacity: 0;
            transform: translateY(-1rem);
            transition-duration: .2s;
            transition-property: max-height;
            transition-timing-function: ease-out;
        }

        &.active {

            .content {
                opacity: 1;
                transform: translateY(0);
                transition-duration: .2s;
                transition-property: opacity, transform;
                transition-timing-function: ease-in-out;
                //min-height: 260px;
                padding: 31px 65px;
            }
        }

        &:not(.active) {
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            position: relative;
        }
    }

    @media screen and (max-width: 767px) {
        .t7t-accordion {
            padding: 30px 25px;
        }

        .t7t-panel.active .content {
            padding: 31px 25px;
        }
    }
}