.mp-calculator-plugin {
    .calculator-results-next-steps {
        background-color: #FFF;
        border-radius: 0px;
        min-height: 296px;
        padding: 37px 21px;
        box-sizing: border-box;
        margin-bottom: 20px;

        &_heading {
            font-weight: 600;
            font-size: 32px;
            line-height: 43.58px;
            color: #000;
            font-family: 'Open Sans', sans-serif;
        }

        &_text {
            font-weight: 400;
            font-size: 16px;
            line-height: 21.79px;
            color: #0D2a25;
            margin-top: 20px;
        }

        &_button {
            margin: auto;
            width: fit-content;
            margin-top: 35px;

            .btn {
                padding: 13px 20px;
                border-radius: 4px;
                min-width: 131px;
                box-sizing: border-box;
                height: 48px;
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                font-weight: 700;
                line-height: 21.79px;

                &:nth-child(2) {
                    margin-left: 20px;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .calculator-results-next-steps_button {
            display: flex;
            flex-direction: column;

            .btn {
                display: block;
                width: 100%;
                margin-left: 0px !important;
                margin-bottom: 10px;
            }
        }
    }
}
